import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, RelationTypeDto, getEnumOptions } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';

@Component({
  selector: 'app-relation-type-item-form',
  templateUrl: './relation-type-item-form.component.html',
  styleUrls: ['./relation-type-item-form.component.scss'],
})
export class RelationTypeItemFormComponent extends BaseForm<RelationTypeDto> implements OnInit {
  riskCascadeList = getEnumOptions(RelationTypeDto.RiskCascadeDirectionEnum);
  complianceModeList = getEnumOptions(RelationTypeDto.ComplianceModeEnum);

  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, 'RELATION_TYPE');
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    RelationTypeDto.ComplianceModeEnum.None;
    this.formGroup = new FormGroup({
      color: new FormControl(null, Validators.required),
      name: new FormControl(null, Validators.required),
      riskCascadeDirection: new FormControl(RelationTypeDto.RiskCascadeDirectionEnum.None, Validators.required),
      complianceMode: new FormControl(RelationTypeDto.ComplianceModeEnum.None, Validators.required),
      description: new FormControl(null),
      systemGenerated: new FormControl(false),
      icon: new FormControl(null),
    });
  }
}
