<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <p-divider class="w-full" align="left">
      <b>Relation Info</b>
    </p-divider>
    <div class="w-6">
      <div [class]="(fieldViewMode == 'edit' ? 'mb-3' : '') + ' col-12'">
        <app-basic-input
          [label]="moduleFieldString + '.name.label' | translate: 'Name'"
          [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Name'"
          [control]="formGroup?.controls?.name"
          [viewMode]="fieldViewMode == 'create' ? 'create' : 'view'"
          [helpText]="fieldViewMode == 'create' ? 'Name is not changeable after creation' : null"
        ></app-basic-input>
      </div>
      <div class="col-12">
        <app-icon-selector [control]="formGroup?.controls?.icon" [viewMode]="fieldViewMode"> </app-icon-selector>
      </div>

      <div class="col-12">
        <app-color-input
          [label]="moduleFieldString + '.color.label' | translate: 'Color'"
          name="Color"
          [control]="formGroup?.controls?.color"
          [viewMode]="fieldViewMode"
        >
        </app-color-input>
      </div>
    </div>

    <!-- <div class="col-12 md:col-6">
  <app-check-box
    [label]="'System Generated'" [name]="'systemGenerated'" [control]="formGroup?.controls?.systemGenerated"
    [viewMode]="fieldViewMode"
    >
  </app-check-box>
</div> -->
    <div class="w-6">
      <div class="col-12 md:col-12">
        <app-text-editor
          [name]="'description'"
          [label]="moduleFieldString + '.description.label' | translate: 'Description'"
          [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Description...'"
          [control]="formGroup?.controls?.description"
          [viewMode]="fieldViewMode"
          [advanced]="true"
        ></app-text-editor>
      </div>
    </div>
    <p-divider class="w-full" align="left">
      <b>Risk Specifications</b>
    </p-divider>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="moduleFieldString + '.riskCascadeDirection.label' | translate: 'Risk Cascade Direction'"
        [placeholder]="moduleFieldString + '.riskCascadeDirection.placeholder' | translate: 'Select Item'"
        [name]="'multi-dropdown'"
        [optionLabel]="'label'"
        [multi]="false"
        [items]="riskCascadeList"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.riskCascadeDirection"
        [badgeView]="true"
        [viewMode]="fieldViewMode"
        [showClear]="false"
      ></app-drop-down-input>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="moduleFieldString + '.complianceMode.label' | translate: 'Compliance Mode'"
        [placeholder]="moduleFieldString + '.complianceMode.placeholder' | translate: 'Select Item'"
        [name]="'multi-dropdown'"
        [optionLabel]="'label'"
        [multi]="false"
        [items]="complianceModeList"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.complianceMode"
        [badgeView]="true"
        [viewMode]="fieldViewMode"
        [showClear]="false"
      ></app-drop-down-input>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
